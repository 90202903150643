

// export default function converter() {
// 	function renderBlock(block) {
// 		switch (block.type) {
// 			case 'paragraph':
// 				var textNode = document.createTextNode(block.data.text);
// 				return textNode;
// 			// Adicione mais casos aqui para outros tipos de blocos
// 			default:
// 				return null;
// 		}
// 	}

// 	function renderPost(data, container) {
// 		data.blocks.forEach(block => {
// 			var element = renderBlock(block);

// 			console.log(element)
// 			if (element) {

// 				console.log(element)

				

// 				console.log(container);
// 				// container.appendChild(element);

// 				container2.textContent = '';
// 				var textNode = document.createTextNode(container2.innerHTML);
// 				container2.innerHTML = element;
// 				container2.appendChild(textNode);


// 			}
// 		});
// 	}
	
// 	// Exemplo de uso:
// 	var data = {"time":1711496734543,"blocks":[{"type":"paragraph","data":{"text":"Estamos mapeando destinos."}},{"type":"paragraph","data":{"text":"Em breve, você vai viajar pelo nosso Roteiro da Gastronomia de Território."}}],"version":"2.18.0"};
// 	var container = document.querySelector('.post-container')
// 	var container2 = document.querySelector('.post-container').childNodes[1].children[1];
	
// 	renderPost(data, container);


// 	container2.remove		

// 	console.log(container2)
// }



export default function mottaEquipeText() {
  function richTextParse() {
    function parseJSONToHTML(json) {
      const data = JSON.parse(json);

      let html = "";

      for (const block of data.blocks) {
        switch (block.type) {
          case "paragraph":
            html += `<p class="paragraph">${block.data.text}</p>`;
            break;
          case "Header":
            html += `<h${block.data.level} class="header">${block.data.text}</h${block.data.level}>`;
            break;
          case "Table":
            html += `<table class="table">${block.data.content}</table>`;
            break;
          case "Raw":
            html += `<div class="raw">${block.data.html}</div>`;
            break;
          case "Delimiter":
            html += '<hr class="delimiter">';
            break;
          case "Code":
            html += `<pre class="code"><code>${block.data.code}</code></pre>`;
            break;
          case "Quote":
            html += `<blockquote class="quote">${block.data.text}</blockquote>`;
            break;
          case "List":
            const listItems = block.data.items
              .map((item) => `<li>${item}</li>`)
              .join("");
            const listStyle = block.data.style === "ordered" ? "ol" : "ul";
            html += `<${listStyle} class="list">${listItems}</${listStyle}>`;
            break;
          case "Embed":
            html += `<div class="embed ${block.data.service}">
								<iframe width="560" height="315" src="${block.data.embed}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
								</div>`;
            break;
          case "Checklist":
            const checklistItems = block.data.items
              .map((item) => {
                const checked = item.checked ? "checked" : "";
                return `<li>
									<input  type="checkbox" onclick="return false;"  ${checked}>
									<label> ${item.text} </label>
									</li>`;
              })
              .join("");
            html += `<ul>${checklistItems}</ul>`;
            break;
          case "Image":
            html += `<img src="${block.data.file.url}" alt="${block.data.caption}" class="image">`;
            break;
          case "Warning":
            html += `<div class="warning">
															<h3>${block.data.title}</h3>
															<p>${block.data.message}</p>
															</div>`;
            break;
          default:
            // Handle unknown block types here
            break;
        }
      }

      return html;
    }

    const blogContent = document.querySelectorAll(".text-content");

    blogContent.forEach((blog) => {
      console.log("caiu aqui =>", blog);

      if (blog.dataset.primeiraSecaoTreguaCozinhaDescricaoPt) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.primeiraSecaoTreguaCozinhaDescricaoPt
        );
        blog.innerHTML = htmlParsed;
      }


			if (blog.dataset.primeiraSecaoTreguaCozinhaDescricaoEn) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.primeiraSecaoTreguaCozinhaDescricaoEn
        );
        blog.innerHTML = htmlParsed;
      }


			if (blog.dataset.primeiraSecaoTreguaCozinhaDescricaoPtEsquerda) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.primeiraSecaoTreguaCozinhaDescricaoPtEsquerda
        );
        blog.innerHTML = htmlParsed;
      }

			if (blog.dataset.primeiraSecaoTreguaCozinhaDescricaoEnEsquerda) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.primeiraSecaoTreguaCozinhaDescricaoEnEsquerda
        );
        blog.innerHTML = htmlParsed;
      }





			if (blog.dataset.primeiraFooterAddress) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.primeiraFooterAddress
        );
        blog.innerHTML = htmlParsed;
      }

			if (blog.dataset.idealizadoraDescriptionPt) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.idealizadoraDescriptionPt
        );
        blog.innerHTML = htmlParsed;
      }
			if (blog.dataset.idealizadoraDescriptionEn) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.idealizadoraDescriptionEn
        );
        blog.innerHTML = htmlParsed;
      }



			if (blog.dataset.destinoDescricaoDireitaPt) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.destinoDescricaoDireitaPt
        );
        blog.innerHTML = htmlParsed;
      }

			if (blog.dataset.destinoDescricaoDireitaEn) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.destinoDescricaoDireitaEn
        );
        blog.innerHTML = htmlParsed;
      }

			if (blog.dataset.destinoDescricaoEsquedaPt) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.destinoDescricaoEsquedaPt
        );
        blog.innerHTML = htmlParsed;
      }

			if (blog.dataset.destinoDescricaoEsquedaEn) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.destinoDescricaoEsquedaEn
        );
        blog.innerHTML = htmlParsed;
      }




			if (blog.dataset.institutoHeaderTituloPt) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.institutoHeaderTituloPt
        );
        blog.innerHTML = htmlParsed;
      }

			if (blog.dataset.institutoHeaderTituloEn) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.institutoHeaderTituloEn
        );
        blog.innerHTML = htmlParsed;
      }


			if (blog.dataset.cardMapaTituloSuperiorPt) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.cardMapaTituloSuperiorPt
        );
        blog.innerHTML = htmlParsed;
      }



			if (blog.dataset.cardMapadescricaoInferiorPt) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.cardMapadescricaoInferiorPt
        );
        blog.innerHTML = htmlParsed;
      }


			if (blog.dataset.cardMapaTituloSuperiorEn) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.cardMapaTituloSuperiorEn
        );
        blog.innerHTML = htmlParsed;
      }















			
			if (blog.dataset.cardDescricaoPt) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.cardDescricaoPt
        );
        blog.innerHTML = htmlParsed;
      }

			if (blog.dataset.cardDescricaoEn) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.cardDescricaoEn
        );
        blog.innerHTML = htmlParsed;
      }

      if (blog.dataset.primeiraSecaoTreguaCozinhaEn) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.primeiraSecaoTreguaCozinhaEn
        );
        blog.innerHTML = htmlParsed;
      } else {
        console.log("SEM CONTEUDO ;");
      }





      if (blog.dataset.respostaFaqPt) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.respostaFaqPt
        );
        blog.innerHTML = htmlParsed;
      }

			if (blog.dataset.respostaFaqEn) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.respostaFaqEn
        );
        blog.innerHTML = htmlParsed;
      }

      if (blog.dataset.respostaFaqEs) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.respostaFaqEs
        );
        blog.innerHTML = htmlParsed;
      } else {
        console.log("SEM CONTEUDO ;");
      }

      



      if (blog.dataset.descriptionContactPt) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.descriptionContactPt
        );
        blog.innerHTML = htmlParsed;
      }

			if (blog.dataset.descriptionContactEn) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.descriptionContactEn
        );
        blog.innerHTML = htmlParsed;
      }

      if (blog.dataset.descriptionContactEs) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.descriptionContactEs
        );
        blog.innerHTML = htmlParsed;
      } else {
        console.log("SEM CONTEUDO ;");
      }





      
      if (blog.dataset.primeiraSecaoTreguaCozinhaDescricaoEnEsquerda) {
        const htmlParsed = parseJSONToHTML(
          blog.dataset.primeiraSecaoTreguaCozinhaDescricaoEnEsquerda
        );
        blog.innerHTML = htmlParsed;
      }

    });
  }
  richTextParse();
}
